import request from '@/utils/request'

/**
 * 分页查询入库订单
 * @param {Object} data
 * @returns
 */
export function getAll (data) {
  return request({
    url: '/api/OutStorage/GetAll',
    method: 'post',
    data,
  })
}

/**
 * 获取入库订单类型
 * @returns
 */
export function getOutStorageTypeOptions () {
  return request({
    url: '/api/OutStorage/GetTypeOptions',
    method: 'get',
  })
}

/**
 * 新增入库单
 * @param {Object} data
 * @returns
 */
export function addNew (data) {
  return request({
    url: '/api/OutStorage/AddNew',
    method: 'post',
    data,
  })
}

/**
 * 根据Id获取入库单详情
 * @param {int} id
 * @returns
 */
export function getInfoById (id) {
  return request({
    url: '/api/OutStorage/GetInfoById',
    method: 'get',
    params: { id },
  })
}

/**
 * 更具入库单id获取入库单提交入库单审核申请
 * @param {int} orderId
 * @returns
 */
export function upAudite (orderId) {
  return request({
    url: '/api/OutStorage/UpAudite',
    method: 'post',
    params: { orderId },
  })
}

/**
 * 更具入库单号提交入库单审核情况
 * @param {int} orderId
 * @param {boolean} isCheck
 * @param {String} remark
 * @returns
 */
export function auditOrder (orderId, isCheck, remark) {
  return request({
    url: '/api/OutStorage/Audit',
    method: 'post',
    params: { orderId },
    data: {
      isCheck,
      remark,
    },
  })
}

/**
 * 根据入库单ID对入库单商品进行入库处理
 * @param {int} orderId
 * @param {string} courierNumber
 * @param {Array}} data
 * @returns
 */
export function outGoods (orderId, courierNumber, data) {
  return request({
    url: '/api/OutStorage/DeliveryGoods',
    method: 'post',
    data: {
      orderId,
      courierNumber,
      data,
    },
  })
}

/**
 * 根据Id更新入库单内容
 * @param {int} id
 * @param {Object} data
 * @returns
 */
export function updateOutStorage (id, data) {
  return request({
    url: 'api/OutStorage/Update',
    method: 'post',
    params: { id },
    data,
  })
}

/**
 * 更新订单快递信息
 * @param {Object} data
 * @returns
 */
export function updateKd (data) {
  return request({
    url: 'api/OutStorage/UpdateDelivery',
    method: 'post',
    data,
  })
}

/**
 * 删除出库单
 * @param {int} id
 * @returns
 */
export function deleteOutStorage (id) {
  return request({
    url: 'api/OutStorage/DeleteOutStorage',
    method: 'get',
    params: { id },
  })
}

/**
 * 获取出货单状态
 * @returns
 */
export function getOutStorageState () {
  return request({
    url: 'api/OutStorage/GetStateOptions',
    method: 'get',
  })
}

/**
 * 获取报表状态
 * @returns
 */
export function getTimeFilterOptions () {
  return request({
    url: 'api/OutStorage/getTimeFilterOptions',
    method: 'get',
  })
}

export function getProductTypeOptions () {
  return request({
    url: 'api/OutStorage/getProductTypeOptions',
    method: 'get',
  })
}

/**
 * 根据用户分组进行销售统计
 * @param {Object} filter
 * @returns
 */
export function getReportUserSale (filter) {
  return request({
    url: 'api/OutStorage/getReportUserSale',
    method: 'post',
    data: filter,
  })
}

/**
 * 根据商品分组进行销售统计
 * @param {Object} filter
 * @returns
 */
export function getReporProduct (filter) {
  return request({
    url: 'api/OutStorage/GetReportProductSale',
    method: 'post',
    data: filter,
  })
}

export function getMyReporProduct (filter) {
  return request({
    url: 'api/OutStorage/GetMyReportProductSale',
    method: 'post',
    data: filter,
  })
}

/**
 * 根据商品分组进行销售统计
 * @param {Object} filter
 * @returns
 */
export function getReporProductNumber (filter) {
  return request({
    url: 'api/OutStorage/GetReportProductSaleNumber',
    method: 'post',
    data: filter,
  })
}

export function getMyReporProductNumber (filter) {
  return request({
    url: 'api/OutStorage/GetMyReportProductSaleNumber',
    method: 'post',
    data: filter,
  })
}

/**
 * 获取用户销售日期报表
 * @param {Object} filter
 * @returns
 */
export function userSaleDateTimeReport (filter) {
  return request({
    url: 'api/OutStorage/UserSaleDateTimeReport',
    method: 'post',
    data: filter,
  })
}


export function myUserSaleDateTimeReport (filter) {
  return request({
    url: 'api/OutStorage/MyUserSaleDateTimeReport',
    method: 'post',
    data: filter,
  })
}

/**
 * 获取用户销售日期报表
 * @param {Object} filter
 * @returns
 */
export function getProductTimeSendNumber (filter) {
  return request({
    url: 'api/OutStorage/GetProductTimeSendNumber',
    method: 'post',
    data: filter,
  })
}

/**
 * 
 * @param {Object} filter
 * @returns
 */
export function getProductTimeSendExcelFile (filter) {
  return request({
    url: 'api/OutStorage/GetProductTimeSendExcelFile',
    method: 'post',
    data: filter,
    responseType: 'blob',
  })
}


export function getMonthOrder (filter) {
  return request({
    url: 'api/OutStorage/GetMonthOrder',
    method: 'post',
    data: filter,
  })
}


export function getMyMonthOrder (filter) {
  return request({
    url: 'api/OutStorage/GetMyMonthOrder',
    method: 'post',
    data: filter,
  })
}
